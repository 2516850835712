






























































































import { Vue, Component } from "vue-property-decorator";
import ContactBox from '../../components/ContactBox.vue';
import ContainersList from '../../components/ContainersList.vue';
import Locations, { Location } from "../../store/locations";
import App from "../../store/app";
import * as R from "ramda";
import { Container } from "../../../../../backend/sparkl-backend-common/src/locations/locations.types";

type FetchedContainers = {
  id: string,
  name: string,
  size: string,
  image_url: string
}

@Component({
  components: {
    ContactBox,
    ContainersList
  }
})
export default class extends Vue {
  model = {
    slug: "",
    name: "",
    desc: "",
    containers: [] as any,
    isVendor: false,
    isReturnStation: false,
    city: "",
    streetAddress: "",
    zipCode: "",
    state: "",
    type: "",
    contacts: [] as any,
    lat: 0,
    lng: 0,
    website: '',
  };
  originalModel: any = {};
  error = "";
  isLoading = false;
  slugAvailable: any = null;
  slugStatusMessage = "";
  fetchedContainers: FetchedContainers[] = [];
  loadingDelete = false;

  get mapCenter() {
    if (this.isMarkerSet) {
      return this.markerLocation;
    }

    return { lat: 40, lng:-100 };
  }

  get mapZoom() {
    return this.isMarkerSet ? 10 : 3;
  }

  get markerLocation() {
    return { lat: this.model.lat, lng: this.model.lng };
  }

  get isMarkerSet() {
    if (this.model && this.model.lat && this.model.lng) {
      return true;
    }

    return false;
  }

  get isEdit() {
    return !!this.$route.params.slug;
  }

  get slug() {
    return this.$route.params.slug;
  }

  get locationType() {
    const type = this.$route.params.type;
    return type === 'vendor' ? 'vendor' : 'return station';
  }

  async mounted() {
    this.isLoading = true;
    try {
      const containersRespone = await Locations.fetchContainers();
      this.fetchedContainers = containersRespone.data as FetchedContainers[];
      if (this.isEdit) {
          const response = await Locations.fetchOne({ slug: this.slug });
          const fetchedLocation = response.data as Location;
          (this.model as any) = R.clone(fetchedLocation);
          this.originalModel = R.clone(fetchedLocation);
      }
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  async save() {
    this.isLoading = true;
    try {
      await this.geocode();
      if (this.isEdit) {
        await this.saveEdited();
      } else {
        await this.create();
      }
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  async checkSlug() {
    this.isLoading = true;
    this.slugStatusMessage = "";
    try {
      const response = await Vue.$axios.get(`/check-location-slug/${this.model.slug}`);
      this.slugAvailable = !response.data.found;
      this.slugStatusMessage = this.slugAvailable ? "Slug available" : "Slug not available"
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  async create() {
    const { slug, name, city, streetAddress, containers, zipCode, state, isVendor, isReturnStation, contacts, lat, lng, desc } = this.model; 
  
    const response = await Locations.create({
        slug,
        name,
        containers,
        city,
        streetAddress,
        zipCode,
        state,
        isVendor,
        isReturnStation,
        contacts,
        lat, 
        lng,
        desc
    });
    const newLocation: Location = response.data; 
    this.$router.push(`/locations/details/${newLocation.slug}`);
    App.showSnackbar({ message: "Location has been created" });
  }

  get changes() {
    const retUndef = (arg: any) => (arg ? arg : undefined);
    const eq = (a1: any, a2: any) => (R.equals(a1, a2) ? false : a1);

    return R.mergeWith(R.compose(retUndef, eq), this.model, this.originalModel);
  }

  async saveEdited() {
    await Locations.update({ slug: this.slug, data: this.changes });

    this.$router.push(`/locations/details/${this.slug}`);
    App.showSnackbar({ message: "Location has been updated" });
  }

  removeContact(index: number) {
    this.model.contacts.splice(index, 1);
    (this.$refs as any).contactbox[index].closeDialog();
    this.$forceUpdate();
  }

  addContact() {
    const index = this.model.contacts.length;
    this.model.contacts.push({
      name: '',
      title: '',
      phone: '',
      email: '',
      isPrimary: false,
      notes: ''
    });
    this.$nextTick(() => {
      (this.$refs as any).contactbox[index].openDialog();
    });
  }

  editContainers() {
    this.$nextTick(() => {
      (this.$refs as any).containerslist.openDialog();
    });
  }

  confirmContainers(data: Container[]) {
    this.model.containers = data;
  }

  async geocode() {
    const response = await Locations.geocodeAddress({
      address: `${this.model.streetAddress} ${this.model.city} ${this.model.zipCode} ${this.model.state}`,
    });

    const { location } = response.results[0].geometry;
    this.model.lng = location.lng;
    this.model.lat = location.lat;
  }

  async remove() {
    this.loadingDelete = true;
    try {
      const doContinue = confirm("Are you sure you want to delete this location?");
      
      if (!doContinue) {
        this.loadingDelete = false;
        return;
      }

      await Locations.remove({ slug: this.slug });
      window.alert("Location deleted.");
      this.$router.go(-1);
    } catch (err) {
      window.alert(`Couldn't remove location: ${err.message}`)
    }
    this.loadingDelete = false;
  }
}
